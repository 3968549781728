import React, { useEffect, useState } from 'react';
import {
  Link,
  useLocation,
} from 'react-router-dom';

import { DateTime } from 'luxon';

import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  writeBatch,
} from 'firebase/firestore';

import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import {
  ArrowBack,
} from '@mui/icons-material';
import {
  styled,
} from '@mui/material/styles';
import {
  ChangeSet,
  EditingState,
  FilteringState,
  SortingState,
  IntegratedFiltering,
  IntegratedSorting,
} from '@devexpress/dx-react-grid';
import {
  Grid as DataGrid,
  Table,
  TableEditColumn,
  TableEditRow,
  TableFilterRow,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';

interface INameOfTheDay {
  date: string;
  name: string;
  dirty?: boolean;
}

const StyledInput = styled('input')({
  display: 'none',
});

const getDateValue = (row: INameOfTheDay) => {
  if (row && row.date && row.date.length > 0) {
    if (row.date.length === 8 && !row.date.includes('/')) {
      return DateTime.fromFormat(row.date, 'yyyyMMdd').toFormat('MM/dd/yyyy');
    } else {
      return row.date;
    }
  } else {
    return '';
  }
};

const getDateIndex = (dateInput: string) => {
  if (dateInput.length === 10 && dateInput.includes('/')) {
    return DateTime.fromFormat(dateInput, 'MM/dd/yyyy').toFormat('yyyyMMdd');
  } else {
    return dateInput;
  }
};

const NameOfTheDay = () => {
  const location = useLocation();

  const [columns] = useState([
    { name: 'date', title: 'Date', getCellValue: getDateValue },
    { name: 'name', title: 'Name' },
  ]);
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(true);
  const [notdList, setNotdList] = useState<INameOfTheDay[]>([]);
  const [saving, setSaving] = useState(false);
  const [uploading, setUploading] = useState(false);

  const refreshData = async () => {
    try {
      const db = getFirestore();
      const snapshot = await getDocs(query(collection(db, 'nameOfTheDay')));
      setNotdList(snapshot.docs.map((it) => ({
        date: it.id,
        name: it.data().name,
        dirty: false,
      })));
      setLoading(false);
    } catch (error) {
      console.log(error);
      alert(error);
      setLoading(false);
    }
  };

  const saveChanges = async (changes?: ChangeSet) => {
    if (!dirty && !changes) {
      return;
    }

    setSaving(true);
    let notdListCopy = notdList;
    let toDelete: string[] = [];

    if (changes) {
      const { added, changed, deleted } = changes;

      if (added) {
        const foo = added.map((val) => ({ date: getDateIndex(val.date), dirty: true, name: val.name }));
        notdListCopy = notdListCopy.concat(foo);
      }

      if (changed) {
        Object.keys(changed).forEach((date: string) => {
          const idx = notdListCopy.findIndex((val) => (val.date === date));
          if (idx !== -1) {
            notdListCopy[idx] = { date: getDateIndex(date), dirty: true, ...changed[date] };
          } else {
            notdListCopy.push({ date: getDateIndex(date), dirty: true, ...changed[date] });
          }
        });
      }

      if (deleted) {
        deleted.forEach((idx) => {
          toDelete.push(getDateIndex(idx as string));
          notdListCopy = notdListCopy.filter((val) => (val.date !== getDateIndex(idx as string)));
        });
      }

      setNotdList(notdListCopy);
    }

    try {
      const db = getFirestore();

      // Adds and updates
      const batch = writeBatch(db);
      notdListCopy.filter((it) => (it.dirty === true)).forEach((it) => {
        batch.set(doc(db, `nameOfTheDay/${it.date}`), { date: it.date, name: it.name }, { merge: true });
      });
      await batch.commit();

      // Deletes
      const deleteBatch = writeBatch(db);
      toDelete.forEach((it) => {
        deleteBatch.delete(doc(db, `nameOfTheDay/${it}`));
      });
      await deleteBatch.commit();

      setDirty(false);
    } catch (error) {
      console.log(error);
      alert(error);
    }

    setSaving(false);
  };

  const uploadData = (ev: React.FormEvent<HTMLInputElement>) => {
    const target = ev.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      setUploading(true);
      const file = target.files[0];
      file.text().then((contents) => {
        let dateSeparator = '';
        if (contents.includes('-')) {
          dateSeparator = '-';
        } else if (contents.includes('/')) {
          dateSeparator = '/';
        }

        const lines = contents.split('\r\n');
        const parsedData: INameOfTheDay[] = [];
        // Skip first line (column headers)
        for (let i = 1; i < lines.length; i++) {
          const row = lines[i].split(',');
          const dateParts = row[0].split(dateSeparator);
          const monthFormat = new Array(dateParts[0].length + 1).join('M');
          const dayFormat = new Array(dateParts[1].length + 1).join('d');
          const yearFormat = new Array(dateParts[2].length + 1).join('y');
          parsedData.push({
            date: DateTime.fromFormat(row[0], `${monthFormat}${dateSeparator}${dayFormat}${dateSeparator}${yearFormat}`).toFormat('yyyyMMdd'),
            name: row[1],
            dirty: true,
          });
        }

        // TODO: Merge data?
        setNotdList(parsedData);
        setUploading(false);
        setDirty(true);
      });
    }
  };

  useEffect(() => {
    refreshData();
  }, []);

  useEffect(() => {
    if (location.state && (location.state as any).refresh === true) {
      refreshData();
    }
  }, [location]);

  if (loading) {
    return (
      <Paper>
        <CircularProgress size="3rem" />
      </Paper>
    );
  }

  return (
    <>
      <Link to="/">
        <Button startIcon={<ArrowBack />} style={{ margin: '1rem' }} variant="outlined">Back</Button>
      </Link>
      <Paper style={{ marginBottom: '2rem', padding: '1rem' }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item sm={8}>
            <Typography variant="h3">Name Of The Day</Typography>
          </Grid>
          <Grid item sm={2}>
            <label htmlFor="upload-data-button">
              <StyledInput accept="text/*" id="upload-data-button" onChange={uploadData} type="file" />
              <Button component="span" disabled={uploading} endIcon={uploading ? <CircularProgress color="secondary" size="1rem" /> : undefined} variant="contained">
                Upload file
              </Button>
            </label>
          </Grid>
          <Grid item sm={2}>
            <Button disabled={saving || !dirty} endIcon={saving ? <CircularProgress color="secondary" size="1rem" /> : undefined} onClick={() => saveChanges()} variant="contained">
              Save changes
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Paper style={{ marginBottom: '2rem', padding: '1rem' }}>
        <DataGrid
          columns={columns}
          getRowId={(row) => row.date}
          rows={notdList}
        >
          <EditingState onCommitChanges={saveChanges} />
          <FilteringState defaultFilters={[]} />
          <IntegratedFiltering />
          <SortingState
            defaultSorting={[{ columnName: 'date', direction: 'asc' }]}
          />
          <IntegratedSorting />
          <Table />
          <TableEditRow />
          <TableEditColumn
            showAddCommand
            showEditCommand
            showDeleteCommand
          />
          <TableHeaderRow showSortingControls />
          <TableFilterRow />
        </DataGrid>
      </Paper>
    </>
  );
};

export default NameOfTheDay;
